<template>
  <div class="videoWrapper">
    <!-- <section
      id="container"
      style="height: 750px"
    /> -->

    <iframe class="iframe" title="Report Section"
      src="https://app.powerbi.com/view?r=eyJrIjoiMmQ0MWZhNzItMWE3NC00YzIyLWFmNDctZDRhYmRlMTlkZDUyIiwidCI6IjRmZmM3ZTJmLTZiNTMtNGFlNC1iYjgwLWE5MjhkNjBmNDU2ZCIsImMiOjEwfQ%3D%3D"
      frameborder="0" allowFullScreen="true" />
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import axios from 'axios'

export default {
  setup() {
    onMounted(async () => {
      const params = new URLSearchParams()
      params.append('emp_id', localStorage.getItem('id'))
      params.append('nik', localStorage.getItem('nik'))
      params.append('report', 'IM Mold Heatmap')
      await axios.post(`${useAppConfig().AxiosUrl}api_mes/user-mes/logreport`, params)
    })
  },
}
</script>

<style>
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
  }

  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
